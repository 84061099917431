import { FaCheck } from '@react-icons/all-files/fa/FaCheck';
import React, { ChangeEvent, forwardRef } from 'react';
import { FieldError } from 'react-hook-form';

import { tw } from '../../utils/tailwind';
import { Box } from '../Core';
import { IQuestion } from '../Newsletter/Newsletter';
import ErrorMessage from './ErrorMessage';

export type Checkbox = Omit<IQuestion, 'required' | 'rows' | 'options'> & {
  value?: string;
  toggleButtonText?: string;
  multiple?: boolean;
  questionName?: string;
  className?: string;
  defaultChecked?: boolean;
  hideError?: boolean;
  errors?: FieldError | FieldError[];
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  register?: unknown;
  type: Extract<QUESTION_TYPE, 'checkbox'>;
  wrapperClassName?: string;
  // mobile checkbox
  typeCheck?: 'alternativeCheck';
  index?: number;
  checkedState?: boolean[];
  setCheckedState?: React.Dispatch<React.SetStateAction<unknown[]>>;
  onFilterChange?: (
    eventOrValue: string[] | ChangeEvent<HTMLInputElement>
  ) => void;
  handleOnChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  label: string;
};

const Checkbox: FCWithClassName<Checkbox> = forwardRef<
  HTMLInputElement,
  Checkbox
>((props, ref) => {
  const checkIconOpacity = props.checkedState?.[props.index]
    ? 'text-opacity-1'
    : 'text-opacity-0';
  switch (props.typeCheck) {
    case 'alternativeCheck':
      return (
        <Box className="relative flex items-center px-4 hover:bg-gray-200 ">
          <input
            type="checkbox"
            name={props.value}
            id={props.name}
            value={props.value}
            onChange={props.handleOnChange}
            className="w-6 h-6 opacity-0 cursor-pointer "
            defaultChecked={
              props.checkedState && props.checkedState[props.index]
            }
          />
          <label htmlFor={props.name} className="font-sans text-2 w-max">
            {props.label}
          </label>
          <FaCheck
            className={tw(
              'w-4 h-4 text-text absolute  top-[5px] cursor-pointer pointer-events-none',
              checkIconOpacity
            )}
          />
        </Box>
      );
    default:
      return (
        <Box className={`flex items-center ${props.wrapperClassName}`}>
          <input
            type="checkbox"
            name={props.name}
            value={props.value}
            defaultChecked={props.defaultChecked}
            className="w-6 h-6 min-w-[1.5rem] mr-4 rounded-sm accent-primaryMain cursor-pointer"
            onChange={props.onChange}
            onBlur={props.onBlur}
            ref={ref}
          />
          <label className="text-3 font-heading uppercase pt-[3px]">
            {props.label}
          </label>
          {props.hideError ? null : <ErrorMessage errors={props.errors} />}
        </Box>
      );
  }
});

export default Checkbox;
Checkbox.displayName = 'Checkbox';

function orderBy<T = Record<string, unknown>>(array: T[], key: keyof T, order?: 'asc' | 'desc'): T[];
function orderBy<T = Record<string, unknown>>(array: T[], callback: (arg0: T) => string | undefined, order?: 'asc' | 'desc'): T[];
function orderBy<T = Record<string, unknown>>(array: T[], iteratee: keyof T | ((arg0: T) => string | undefined), order?: 'asc' | 'desc'): T[] {
  return array?.concat().sort((a,b) => {
    const valueA = typeof iteratee === 'function' ? iteratee(a) : a[iteratee];
    const valueB = typeof iteratee === 'function' ? iteratee(b) : b[iteratee];
    if (order === 'asc') {
      return (valueA > valueB) ? 1 : ((valueB > valueA) ? -1 : 0)
    } else {
      return (valueA < valueB) ? 1 : ((valueB < valueA) ? -1 : 0)
    }
  })
}

export default orderBy;
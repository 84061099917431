import { FaFacebookF } from '@react-icons/all-files/fa/FaFacebookF';
import { FaInstagram } from '@react-icons/all-files/fa/FaInstagram';
import { FiMail } from '@react-icons/all-files/fi/FiMail';
import { FiMapPin } from '@react-icons/all-files/fi/FiMapPin';
import { FiPhone } from '@react-icons/all-files/fi/FiPhone';
import { IoMdPin } from '@react-icons/all-files/io/IoMdPin';
import React from 'react';

import { ButtonStyles } from '../../constants/ui';
import { IWordpressCTA } from '../../types/wordpress/blocks';
import { Box, Stack } from '../Core';
import IconWithText from '../IconWithText';
import Image, { IImage } from '../Image/Image';
import StyledLink from '../StyledLink';
import Text from '../Text';
import { TEXT_STYLES } from '../Text/constants';
import ThemedButton from '../ThemedButton';

interface ICentreCardProps {
  address: string;
  ctas?: IWordpressCTA[];
  distance?: number;
  email: string;
  facebook: string;
  gps: string;
  image: IImage | null;
  instagram: string;
  phone: string;
  title: string;
}

const CentreCard: FCWithClassName<ICentreCardProps> = (props) => {
  const {
    address,
    ctas,
    distance,
    email,
    facebook,
    image,
    instagram,
    phone,
    title,
    ...rest
  } = props;
  return (
    <Box className="max-w-[590px] w-[320px] md:w-[350px] lg:w-[400px] min-w-[250px]">
      <Box className="aspect-w-15 aspect-h-10">
        <Image image={image} />
      </Box>
      <Text
        variant={TEXT_STYLES.H3}
        className="w-full px-4 pt-4 pb-2 text-center bg-primaryMain"
      >
        {title}
      </Text>
      <Stack
        className="relative px-4 pt-4 pb-8 bg-secondaryMain lg:px-10 lg:pt-8 xxl:pt-12 lg:pb-12 xxl:pb-16 text-backgroundMain"
        justify="flex-start"
        align="center"
        {...rest}
      >
        {distance && (
          <IconWithText
            icon={IoMdPin}
            description={`${distance.toFixed(1)}km`}
            iconClassName="w-8 h-8"
            isInline
          />
        )}
        <Stack className="space-y-4 ">
     <StyledLink
       externalLink
       href={"https://www.google.com/maps/place/" + address}
       className="text-xl text-white lg:text-2xl xxl:text-3xl"
     >
            <IconWithText
                description={address}
                icon={FiMapPin}
                isInline
                iconClassName="w-5 h-5 sm:w-6 sm:h-6"
                textClassName="text-xl lg:text-2xl xxl:text-3xl font-heading font-bold text-white leading-5"
            />
          </StyledLink>
          <StyledLink
            shouldObfuscate
            isPhone
            to={phone}
            className="text-xl text-white lg:text-2xl xxl:text-3xl"
          >
            <IconWithText
              description={phone}
              icon={FiPhone}
              iconClassName="w-5 h-5 sm:w-6 sm:h-6"
              textClassName="text-xl lg:text-2xl xxl:text-3xl font-heading font-bold text-white leading-5"
              isInline
            />
          </StyledLink>
          <StyledLink
            shouldObfuscate
            isEmail
            to={email}
            className="text-xl text-white lg:text-2xl xxl:text-3xl"
          >
            <IconWithText
              description={email}
              icon={FiMail}
              iconClassName="w-5 h-5 sm:w-6 sm:h-6"
              textClassName="text-xl lg:text-2xl xxl:text-3xl font-heading font-bold text-white leading-5"
              isInline
            />
          </StyledLink>
          <Stack isInline className="pb-4 space-x-4 text-2">
            <a href={facebook} className="border-b-0 text-text">
              <FaFacebookF className="h-[30px] w-[30px] text-white" />
            </a>
            <a href={instagram} className="border-b-0 text-text">
              <FaInstagram className="h-[30px] w-[30px] text-white" />
            </a>
          </Stack>
          {ctas?.map((cta) => (
            <StyledLink
              className="self-center"
              key={cta.titre}
              to={cta.internalLink?.uri}
              href={cta.externalLink ?? undefined}
              anchorId={cta.anchor}
              state={cta.navigationProps}
            >
              <ThemedButton variant={cta.style as ButtonStyles}>{cta.titre}</ThemedButton>
            </StyledLink>
          ))}
        </Stack>
      </Stack>
    </Box>
  );
};

export default CentreCard;

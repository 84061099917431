import { IconType } from '@react-icons/all-files/lib/cjs';
import React from 'react';
import Obfuscate from 'react-obfuscate';

import { tw } from '../../utils/tailwind';
import { Box, Flex, Stack } from '../Core';
import Text from '../Text';
import { TEXT_STYLES } from '../Text/constants';

interface IPropsWithDefault {
  color?: string;
  isInline?: boolean;
  fontColor?: string;
  mb?: number;
  iconMargin?: 'short' | 'regular';
}

interface IPropsWithoutDefault {
  heading?: string;
  description: string | undefined;
  icon: IconType | JSX.Element;
  iconClassName?: string;
  type?: 'phone' | 'email';
  textStyle?: object;
  textClassName?: string;
}

type IconWithTextProps = IPropsWithoutDefault & IPropsWithDefault;

const IconWithText: FCWithClassName<IconWithTextProps> = (props) => {
  const {
    color = 'inherit',
    description,
    heading,
    icon,
    isInline = false,
    type,
    iconClassName,
    textClassName,
  } = props;
  const formattedDescription =
    type === 'email' ? (
      <Obfuscate email={description} />
    ) : type === 'phone' ? (
      <Obfuscate tel={description} />
    ) : (
      <Text style={{ color }} className={tw('pt-1', textClassName)}>
        {description}
      </Text>
    );
  const displayIcon = (propsIcon: IconType | JSX.Element) => {
    if (typeof propsIcon === 'object' && propsIcon !== null) {
      return <Box className={iconClassName}>{propsIcon}</Box>;
    } else {
      const Icon = propsIcon;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore TODO: improve typing here
      return <Icon className={iconClassName}/>;
    }
  };
  return (
    <Stack
      isInline={isInline}
      className={tw('text-left items-center space-x-3', props.className)}
    >
      {displayIcon(icon)}
      <Flex
        className={`${
          isInline ? 'text-left' : 'text-center'
        } break-words w-fit line leading-tight`}
        direction="column"
      >
        <Text variant={TEXT_STYLES.H3} className={textClassName}>
          {heading}
        </Text>
        {formattedDescription}
      </Flex>
    </Stack>
  );
};

export default IconWithText;

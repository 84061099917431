import { graphql, PageProps } from 'gatsby';
import React, { BaseSyntheticEvent, useEffect, useState } from 'react';

import { Flex } from '../../components/Core';
import Input from '../../components/Form/Input';
import Radio from '../../components/Form/Radio';
import Layout from '../../components/Layout';
import SectionWithTitle from '../../components/SectionWithTitle';
import SEO from '../../components/SEO';
import Text from '../../components/Text';
import { useDebounce } from '../../hooks/useDebounce';
import { INavigationProps } from '../../types/wordpress/blocks';
import { isSafariIos } from '../../utils/device';
import { getDistance, getLatAndLngFromString } from '../../utils/geolocation';
import compact from '../../utils/helpers/compact';
import intersection from '../../utils/helpers/intersection';
import orderBy from '../../utils/helpers/orderBy';
import CentreCardsSection from '../components/RechercheCentre/CentreCardsSection';
import CentreFilters from '../components/RechercheCentre/CentreFilters';

type IRechercheCentreTemplate = PageProps<
  Queries.PageRechercheCentreQuery,
  IPageContext,
  Pick<INavigationProps, 'geolocation' | 'services'>
>;

export type CentreWithDistance =
  Queries.PageRechercheCentreQuery['allWpCentre']['nodes'][number] & {
    distance: number;
  };

export type Centre = Queries.PageRechercheCentreQuery['allWpCentre']['nodes'][number];

const RechercheCentrePageTemplate: FCWithClassName<IRechercheCentreTemplate> = (
  props
) => {
  const { data, pageContext } = props;
  const pageData = data.wpPage;
  const centres = data.allWpCentre.nodes;
  const services = data.allWpService.nodes;
  const pageTemplateData = 'contenuRechercheCentre' in data.wpPage.template ? data.wpPage.template.contenuRechercheCentre : null;
  if (!pageData || !pageContext.defaultLanguage || !pageTemplateData) return null;
  const formulaireRecherche = 'formulaireRechercheCentre' in pageData.template ? pageData.template.formulaireRechercheCentre : null
  const navigationProps = props.location.state;
  const geolocationProps = navigationProps?.geolocation;
  const servicesProps = navigationProps?.services;
  const [geolocation, setGeolocation] =
    useState<INavigationProps['geolocation']>(geolocationProps);
  const [activeFilters, setActiveFilters] = useState({
    distance: 999999,
    services: servicesProps ?? [],
  });
  const [shouldDisplayGeoInput, setShouldDisplayGeoInput] = useState(false);
  const { distance: distanceFilter, services: servicesFilter } = activeFilters;
  const [filteredCentres, setFilteredCentres] =
    useState<readonly (CentreWithDistance | Centre)[]>(centres);
  const filters = pageTemplateData.sectionResults?.filters;
  // set filtered centres every time the filters change
  useEffect(() => {
    const centresFilteredByServices = centres.filter((centre) => {
      if (!centre) return undefined;
      const centreServices =
        centre.servicesEtStaff.servicesDisponibles?.map(
          (service) => service.slug
        ) ?? [];
      return servicesFilter.length === 0
        ? true
        : intersection(servicesFilter, centreServices).length > 0;
    });
    if (!geolocation) {
      setFilteredCentres(centresFilteredByServices);
      return;
    }
    const centresWithDistance: CentreWithDistance[] = compact(
      centresFilteredByServices?.map((centre) => {
        const centreLocation = getLatAndLngFromString(centre.coordonnees?.gps);
        if (!centreLocation) return undefined;
        return {
          ...centre,
          distance: getDistance({
            lat1: geolocation.lat,
            lat2: centreLocation.lat,
            lng1: geolocation.lng,
            lng2: centreLocation.lng,
          }),
        };
      })
    );
    const centresFilteredByDistance = centresWithDistance.filter(
      (centre: CentreWithDistance | Centre) => {
        // if the user picks the placeholder value, then we return all the centres
        if (isNaN(distanceFilter)) return true;
        if ('distance' in centre)
          return (centre?.distance || 0) <= distanceFilter;
        return false;
      }
    );
    const centresFilteredAndSortedByDistance = orderBy(
      centresFilteredByDistance,
      'distance'
    );
    setFilteredCentres(centresFilteredAndSortedByDistance);
  }, [geolocation, distanceFilter, servicesFilter]);

  useEffect(() => {
    // geolocation is not working on Safari IOS as of september 2022
    if (!isSafariIos()) setShouldDisplayGeoInput(true);
  }, [])
  const onChange = ({
    fieldName,
    value,
  }: {
    fieldName: 'distance' | 'services' | 'address';
    value: unknown;
  }) => {
    if (fieldName === 'services') {
      const fieldValue = value as string[];
      setActiveFilters({
        ...activeFilters,
        services: compact(fieldValue),
      });
    } else if (fieldName === 'distance') {
      const fieldValue = value as number;
      setActiveFilters({
        ...activeFilters,
        distance: fieldValue,
      });
    }
  };
  const [address, setAddress] = useState<string | null | undefined>(
    geolocation?.address
  );
  const debouncedAddress = useDebounce(address, 1000);
  const onDistanceChange = (value: unknown): void =>
    onChange({
      fieldName: filters?.[0]?.slug as 'distance',
      value,
    });
  const onServicesChange = (value: unknown): void =>
    onChange({
      fieldName: filters?.[1]?.slug as 'services',
      value,
    });
  const onAddressChange = (event: BaseSyntheticEvent): void =>
    setAddress(event.target.value);
  useEffect(() => {
    const getGeocodingResponse = async () => {
      if (!debouncedAddress) return;
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          debouncedAddress
        )}&region=be&key=${process.env.GATSBY_GEOCODING_API_KEY}`
      );
      const data = await response?.json();
      if (response.ok) {
        setGeolocation({
          address: data.results[0]?.formatted_address,
          lat: data.results[0]?.geometry.location.lat,
          lng: data.results[0]?.geometry.location.lng,
        });
      }
    };
    getGeocodingResponse();
  }, [debouncedAddress]);
  const getCurrentPosition = () => {
    navigator.geolocation?.getCurrentPosition(
      (position) => {
        setGeolocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };
  const isGeolocationRadioChecked = Boolean(
    geolocation?.lat && geolocation?.lng && !geolocation?.address
  );
  const renderCardSection = () => {
    if (filteredCentres.length === 0) {
      return (
        <Text className="pt-16 mx-auto">
          {
            pageTemplateData.sectionResults
              ?.messageResultatsVides
          }
        </Text>
      );
    } else {
      const kinequickService = services?.find(
        (service) => service.slug === activeFilters.services?.[0]
      );
      return (
        <CentreCardsSection
          centres={filteredCentres}
          centrePageLabel={
            pageTemplateData.sectionResults?.centrePageLabel
          }
          destinationLabel={
            pageTemplateData.sectionResults?.destinationLabel
          }
          selectedService={kinequickService}
        />
      );
    }
  };
  return (
    <Layout
      currentLanguage={pageContext.currentLanguage}
      currentPath={pageData.link}
      menuData={pageContext.menus}
      siteSettings={pageContext.siteSettings}
      submenuItems={pageContext.submenuItems}
      translations={pageData.translations}
    >
      <SEO pageData={pageData} />
      <SectionWithTitle className="min-h-[700px] mt-4">
        <Flex
          direction={{ base: 'column', xl: 'row-reverse' }}
          className="w-full"
        >
          <Flex
            align={{ base: 'flex-start', lg: 'center' }}
            direction={{ base: 'column', lg: 'row' }}
            className="w-full mb-8 xl:ml-16 xl:mb-0"
          >
            <Input
              type="text"
              name="address"
              onChange={onAddressChange}
              defaultValue={address ?? undefined}
              placeholder={
                formulaireRecherche.formulaireRechercheDeCentre
                  ?.adresse ?? undefined
              }
              wrapperClassName="w-full"
            />
            {shouldDisplayGeoInput && <Radio
              type="radio"
              name="geolocation-input"
              onChange={getCurrentPosition}
              defaultChecked={isGeolocationRadioChecked} // if geolocation is disabled, this cannot be checked
              labelClassName="!text-text !text-lg !leading-none !font-heading  mt-4 lg:mt-0"
              label={
                formulaireRecherche.formulaireRechercheDeCentre?.geolocalisation
              }
              className="!border !border-primaryMain ml-0 lg:ml-4"
                                      />}
          </Flex>
          <CentreFilters
            activeFilters={activeFilters}
            filters={pageTemplateData.sectionResults?.filters}
            services={services}
            onDistanceChange={onDistanceChange}
            onServicesChange={onServicesChange}
          />
        </Flex>
        {renderCardSection()}
      </SectionWithTitle>
    </Layout>
  );
};

export default RechercheCentrePageTemplate;

export const query = graphql`
  query PageRechercheCentre($id: String, $currentLanguageSlug: String) {
    wpPage(id: { eq: $id }) {
      language {
        locale
        slug
      }
      link
      seo {
        ...SEOFragment
      }
      slug
      title
      translations {
        language {
          code
          locale
        }
        uri
      }
      featuredImage {
        node {
          ...MediaItemFragment
          localFile {
            ...HeroImage
          }
        }
      }
      template {
        ...on WpTemplate_PageRechercheDeCentre {
          contenuRechercheCentre {
            sectionResults {
              filters {
                ... on WpTemplate_PageRechercheDeCentre_Contenurecherchecentre_SectionResults_Filters_Filter {
                  choixDisponibles {
                    checked
                    label
                    name
                  }
                  fieldGroupName
                  label
                  obligatoire
                  questionType
                  rows
                  slug
                  toggletext
                }
              }
              messageResultatsVides
              searchButtonLabel
              centrePageLabel
              destinationLabel
              titre
            }
          }
          formulaireRechercheCentre {
            formulaireRechercheDeCentre {
              adresse
              centreLePlusProche
              geolocalisation
              service
              tousServices
              messageErreurGeolocalisation
              pageDeResultats {
                ... on WpPage {
                  uri
                }
              }
            }
          }
        }
      }
    }
    allWpCentre(
      filter: { language: { slug: { eq: $currentLanguageSlug } } }
      sort: { fields: title, order: ASC }
    ) {
      nodes {
        title
        id
        slug
        uri
        featuredImage {
          node {
            ...MediaItemFragment
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 400
                  
                  layout: CONSTRAINED
                )
              }
              publicURL
            }
          }
        }
        coordonnees {
          adresse
          email
          facebook
          gps
          instagram
          telephone
          lienRdv
        }
        servicesEtStaff {
          servicesDisponibles {
            ... on WpService {
              slug
            }
          }
        }
      }
    }
    allWpService(
      filter: { language: { slug: { eq: $currentLanguageSlug } } }
      sort: { fields: title, order: ASC }
    ) {
      nodes {
        id
        slug
        title
        informationsService {
          kinequick {
            type
            speciality
          }
        }
      }
    }
  }
`;

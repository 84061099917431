export const getLatAndLngFromString = (stringCoordinates: string | null | undefined) => {
  if (!stringCoordinates) return null;
  const normalizedCoordinates = stringCoordinates.split(',').map(parseFloat);
  return { lat: normalizedCoordinates[0], lng: normalizedCoordinates[1] };
};

export const getDistance = ({
  lat1,
  lng1,
  lat2,
  lng2,
  unit = 'K',
}: {
  lat1: number;
  lng1: number;
  lat2: number;
  lng2: number;
  unit?: 'K' | 'N';
}) => {
  if (lat1 === lat2 && lng1 === lng2) {
    return 0;
  } else {
    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const theta = lng1 - lng2;
    const radtheta = (Math.PI * theta) / 180;
    let dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === 'K') {
      dist = dist * 1.609344;
    }
    if (unit === 'N') {
      dist = dist * 0.8684;
    }
    return dist;
  }
};

import React, { ChangeEvent, memo, useEffect, useState } from 'react';

import { ServicesDataForDropdown } from '../../../../components/CentresSection/CentresSection';
import { Stack } from '../../../../components/Core';
import Checkbox from '../../../../components/Form/Checkbox';
import Radio from '../../../../components/Form/Radio';
import OutsideClick from '../../../../components/OutsideClick/OutsideClick';
import ThemedButton from '../../../../components/ThemedButton';
import { BUTTON_STYLES } from '../../../../constants/ui';
import without from '../../../../utils/helpers/without';

interface ICentreFiltersProps {
  activeFilters: { distance: number; services: string[] };
  services: readonly (ServicesDataForDropdown | null)[];
  filters: readonly { readonly fieldGroupName: string; readonly label: string; readonly obligatoire: boolean; readonly questionType: string; readonly rows: number; readonly slug: string; readonly toggletext: string; readonly choixDisponibles: readonly { readonly checked: boolean; readonly label: string; readonly name: string; }[]; }[];
  onDistanceChange: (value: string | number) => void;
  onServicesChange: (value: string[] | number[]) => void;
}

const CentreFilters: FCWithClassName<ICentreFiltersProps> = (props) => {
  const {
    activeFilters,
    filters,
    onDistanceChange,
    onServicesChange,
    services,
  } = props;
  const [isOpenDistance, setIsOpenDistance] = useState(false);
  const [isOpenServices, setIsOpenServices] = useState(false);
  const [arrayServices, setArrayServices] = useState<string[]>(
    activeFilters.services
  );
  const [distance, setDistance] = useState<string | number>(
    activeFilters.distance
  );
  const [checkedStateChoix, setCheckedStateChoix] = useState<number>(
    filters[0].choixDisponibles.length - 1
  );
  const [checkedStateServices, setCheckedStateServices] = useState(
    new Array(services.length).fill(false)
  );

  const updateFilteredServices = (
    eventOrValue: ChangeEvent<HTMLInputElement> | string[]
  ) => {
    if (eventOrValue instanceof Array) {
      setArrayServices(eventOrValue);
    } else {
      eventOrValue?.target?.checked
        ? setArrayServices(
          Array.from(new Set([...arrayServices, eventOrValue.target.name]))
        )
        : setArrayServices(without(arrayServices, eventOrValue.target.name));
    }
  };

  const handleClick = (
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
    isOpen: boolean
  ) => {
    setIsOpen(!isOpen);
  };
  // update value of checkbox array
  const updateCheckedState = (
    position: number,
    checkedState: unknown[],
    setCheckedState: React.Dispatch<React.SetStateAction<unknown[]>>
  ) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
  };

  const getIndexForDefaultService = () => {
    const servicesSlug = services?.map((el) => el.slug);
    return servicesSlug.indexOf(activeFilters.services[0]);
  };

  useEffect(() => {
    onDistanceChange(distance);
  }, [distance]);

  useEffect(() => {
    const defaultServiceIndex = getIndexForDefaultService();
    if (defaultServiceIndex) {
      checkedStateServices[defaultServiceIndex] = true;
      setCheckedStateServices(checkedStateServices);
    }
  }, []);

  useEffect(() => {
    onServicesChange(arrayServices);
  }, [arrayServices]);

  return (
    <Stack isInline className="relative justify-center space-x-8">
      <OutsideClick setIsOpen={setIsOpenDistance}>
        <ThemedButton
          variant={BUTTON_STYLES.OUTLINE}
          onClick={() => handleClick(setIsOpenDistance, isOpenDistance)}
        >
          {filters[0].label}
        </ThemedButton>
        <ul
          className={`min-w-[120px] flex flex-col p-0 mt-4 bg-white absolute z-40   ${isOpenDistance ? 'block' : 'hidden'
            }`}
        >
          {filters[0].choixDisponibles?.map((option, index) => {
            return (
              <li key={option.name} className="list-none">
                <Radio
                  type="radio"
                  typeCheck="alternativeRadio"
                  setCheckedStateChoix={setCheckedStateChoix}
                  index={index}
                  lastArrayLength={filters[0].choixDisponibles.length - 1}
                  optionName={option.name}
                  checkedStateChoix={checkedStateChoix}
                  name={activeFilters.distance?.toString()}
                  label={option.label}
                  onChangeValue={setDistance}
                />
              </li>
            );
          })}
        </ul>
      </OutsideClick>
      <OutsideClick setIsOpen={setIsOpenServices}>
        <ThemedButton
          variant={BUTTON_STYLES.OUTLINE}
          onClick={() => handleClick(setIsOpenServices, isOpenServices)}
        >
          {filters[1].label}
        </ThemedButton>
        <ul
          className={`min-w-[240px] flex flex-col p-0 mt-4 bg-white absolute z-40  ${isOpenServices ? 'block' : 'hidden'
            }`}
        >
          {services?.map((service, index) => {
            if (!service) return undefined;
            return (
              <li key={service.id} className="list-none">
                <Checkbox
                  type="checkbox"
                  typeCheck="alternativeCheck"
                  name={service.slug}
                  value={service.slug}
                  index={index}
                  handleOnChange={(e) => {
                    updateFilteredServices(e);
                    updateCheckedState(
                      index,
                      checkedStateServices,
                      setCheckedStateServices
                    );
                  }}
                  checkedState={checkedStateServices}
                  label={service.title}
                />
              </li>
            );
          })}
        </ul>
      </OutsideClick>
    </Stack>
  );
};

export default memo(CentreFilters);

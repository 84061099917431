import { FaCheck } from '@react-icons/all-files/fa/FaCheck';
import React, { forwardRef } from 'react';
import { FieldError } from 'react-hook-form';

import { tw } from '../../utils/tailwind';
import { Box } from '../Core';
import { IQuestion } from '../Newsletter/Newsletter';
import ErrorMessage from './ErrorMessage';

export type Radio = Omit<IQuestion, 'required' | 'rows' | 'options'> & {
  id?: string;
  toggleButtonText?: string;
  multiple?: boolean;
  questionName?: string;
  className?: string;
  defaultChecked?: boolean;
  hideError?: boolean;
  errors?: FieldError | FieldError[];
  labelClassName?: string;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  register?: unknown;
  type: Extract<QUESTION_TYPE, 'radio'>;
  wrapperClassName?: string;
  // alternativeRadio
  typeCheck?: 'alternativeRadio';
  setCheckedStateChoix?: React.Dispatch<React.SetStateAction<number>>;
  index?: number;
  lastArrayLength?: number;
  optionName?: string;
  checkedStateChoix?: number;
  onChangeValue?: React.Dispatch<React.SetStateAction<string | number>>;
};

const Radio: FCWithClassName<Radio> = forwardRef<HTMLInputElement, Radio>(
  (props, ref) => {
    switch (props.typeCheck) {
      case 'alternativeRadio':
        return (
          <Box className="relative flex items-center px-4 py-2 hover:bg-gray-200">
            <input
              type="radio"
              name={props.name}
              id={props.optionName}
              onChange={() => {
                props.onChangeValue(parseInt(props.optionName));
                props.setCheckedStateChoix(props.index);
              }}
              className="w-6 h-6 opacity-0 cursor-pointer"
              defaultChecked={props.index === props.lastArrayLength}
            />
            <label htmlFor={props.optionName} className="font-sans text-2">
              {props.label}
            </label>
            <FaCheck
              className={`w-4 h-4 text-text absolute right-26 top-3 cursor-pointer pointer-events-none ${
                props.checkedStateChoix === props.index
                  ? 'text-opacity-1'
                  : 'text-opacity-0'
              }`}
            />
          </Box>
        );
      default:
        return (
          <Box className={props.wrapperClassName}>
            <label className={tw('flex items-center', props.labelClassName)}>
              <input
                id={props.id}
                type="radio"
                name={props.name}
                defaultChecked={props.defaultChecked}
                className={tw('mr-3', props.className)}
                onChange={props.onChange}
                onBlur={props.onBlur}
                ref={ref}
              />
              {props.label}
            </label>
            {props.hideError ? null : <ErrorMessage errors={props.errors} />}
          </Box>
        );
    }
  }
);

export default Radio;
Radio.displayName = 'Radio';

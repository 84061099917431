import React from 'react';
import { FieldError } from 'react-hook-form';

import { tw } from '../../utils/tailwind';

const ErrorMessage: FCWithClassName<{
  errors: FieldError | FieldError[];
}> = (props) => {
  const message =
    Array.isArray(props.errors)
      ? Object.entries(props.errors)
          .map((error) => error[1])
          .join(', ')
      : props.errors?.message;
  return (
    <p
      className={tw(
        'text-red-500 text-xs',
        props.errors ? 'visible' : 'invisible'
      )}
      role="alert"
    >
      {message}
    </p>
  );
};

export default ErrorMessage;

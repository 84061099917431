import React, { forwardRef } from 'react';
import { FieldError } from 'react-hook-form';

import { tw } from '../../utils/tailwind';
import { Box } from '../Core';
import { IQuestion } from '../Newsletter/Newsletter';
import ErrorMessage from './ErrorMessage';
import Label from './Label';

export type Input = Omit<
  IQuestion,
  'required' | 'toggleButtonText' | 'rows' | 'options'
> & {
  autocomplete?: 'on' | 'off';
  className?: string;
  defaultValue?: string;
  errors?: FieldError | FieldError[];
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type: Extract<QUESTION_TYPE, 'text' | 'email' | 'password'>;
  wrapperClassName?: string;
};

const Input = forwardRef<HTMLInputElement, Input>((props, ref) => {
  const { autocomplete = 'on' } = props;

  return (
    <Box className={props.wrapperClassName}>
      <Label>{props.label}</Label>
      <input
        ref={ref}
        autoComplete={autocomplete}
        className={tw(
          'w-full',
          props.className,
          props.errors ? '!border-red-500' : ''
        )}
        onChange={props.onChange}
        onBlur={props.onBlur}
        placeholder={props.placeholder}
        type={props.type}
        name={props.name}
        defaultValue={props.defaultValue}
      />
      <ErrorMessage errors={props.errors} />
    </Box>
  );
});

export default Input;
Input.displayName = 'Input';

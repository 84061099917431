import React, { memo, useContext } from 'react';

import CentreCard from '../../../../components/CentreCard/CentreCard';
import { Grid } from '../../../../components/Core';
import { BUTTON_STYLES } from '../../../../constants/ui';
import LanguageContext from '../../../../context/LanguageContext';
import { IWordpressCTA } from '../../../../types/wordpress/blocks';
import { getLocalizedString } from '../../../../utils/getLocalizedLink';
import { serializeImageForImageComponent } from '../../../../utils/serializeImageForImageComponent';
import { Centre, CentreWithDistance } from '../../../single/Page-Page Recherche De Centre';

interface ICentreCardsSectionProps {
  centres: readonly (CentreWithDistance | Centre)[];
  centrePageLabel?: string | null;
  destinationLabel?: string | null;
  selectedService: Queries.PageRechercheCentreQuery['allWpService']['nodes'][number] | undefined;
}

const CentreCardsSection: FCWithClassName<ICentreCardsSectionProps> = (
  props
) => {
  const { centrePageLabel, centres, destinationLabel, selectedService, ...rest } =
    props;
  const { currentLanguage } = useContext(LanguageContext);
  return (
    <Grid
      className="gap-8 grid-cols-[repeat(auto-fit,minmax(250px,1fr))] md:grid-cols-[repeat(auto-fit,minmax(350px,1fr))] xxl:grid-cols-[repeat(auto-fit,minmax(400px,1fr))] w-full justify-items-center mt-16"
      {...rest}
    >
      {centres?.map((centre) => {
        if (!centre) return undefined;
        const rdvButtonProps: IWordpressCTA = {
          internalLink: {
            id: '/kinequick',
            uri: getLocalizedString(
              '/rendez-vous',
              '/en/kinequick',
              currentLanguage.slug,
              'fr'
            ),
          },
          navigationProps: {
            idCentre: centre.coordonnees.lienRdv,
            kinequickSpeciality:
              selectedService?.informationsService?.kinequick?.speciality,
            kinequickType:
              selectedService?.informationsService?.kinequick?.type,
            services: [selectedService?.slug],
          },
          style: BUTTON_STYLES.SOLID,
          titre: destinationLabel,
        };
        const goToCentrePageButtonProps = {
          internalLink: { id: centre.id, uri: centre.uri },
          style: BUTTON_STYLES.OUTLINE,
          titre: centrePageLabel,
        };
        return (
          <CentreCard
            ctas={[rdvButtonProps, goToCentrePageButtonProps]}
            key={centre.id}
            address={centre.coordonnees.adresse}
            email={centre.coordonnees.email}
            facebook={centre.coordonnees.facebook}
            gps={centre.coordonnees.gps}
            image={serializeImageForImageComponent({
              image: centre.featuredImage?.node,
            })}
            instagram={centre.coordonnees.instagram}
            phone={centre.coordonnees.telephone}
            title={`Uperform ${centre.title}`}
            distance={'distance' in centre ? centre.distance : null}
          />
        );
      })}
    </Grid>
  );
};

export default memo(CentreCardsSection);
